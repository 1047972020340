import { NotFoundSection } from "@/components/ErrorPages";
import Head from "next/head";

export default function FourOhFour() {
  return (
    <>
      <Head>
        <title>404: Page not found</title>
      </Head>
      <NotFoundSection />
    </>
  );
}
